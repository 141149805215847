export const translationEn = {
  language: 'English',
  dashboard: {
    title: 'Dashboard'
  },
  headers: {
    titleAccount: 'Admin'
  },
  pageTitle: {
    dashboard: 'Dashboard',
    callback: 'callback',
    myDevices: 'My Device',
    devices: 'Devices',
    request: 'Request',
    requestsDetails: 'Requests Details',
    deviceHistory: 'Device',
    users: 'Users',
    userProfile: 'User Profile',
    tenants: 'Tenants',
    groups: 'Groups',
    historyDeviceDetails: 'History device details',
    deviceDetails: 'Device details',
    myGroup: 'My Group',
    deviceGroups: 'Groups/devices',

    personal: {
      title: 'Personal',
      myDevice: 'My Devices',
      search: {
        byCategory: 'Search by category',
        byStatus: 'Search by status',
        minRemainingPrice: 'Min remaining price',
        maxRemainingPrice: 'Max remaining price',
        purchaseDateFrom: 'Purchase Date From',
        purchaseDateTo: 'Purchase Date To',
        borrowDateFrom: 'Borrow Date From',
        borrowDateTo: 'Borrow Date To',
        returnDateFrom: 'Return Date From',
        returnDateTo: 'Return Date To'
      },
      createNew: {
        titleButton: 'Create New',
        titleModal: 'Create device'
      },
      exportFile: 'Export File',
      table: {
        id: 'ID',
        deviceName: 'Device Name',
        assignee: 'Assignee',
        assigneeName: 'Assignee Name',
        status: 'Status',
        category: 'Category',
        deviceType: 'Device Type',
        purchaseDate: 'Purchase date',
        activationDate: 'Activation date',
        warrantyPeriod: 'Warranty period',
        originalPrice: 'Original price',
        allotmentTime: 'Allotment time',
        remainingPrice: 'Remaining price',
        borrowDate: 'Borrow date',
        returnDate: 'Return date',
        actions: 'Actions',
        invoiceNumber: 'Invoice Number',
        supplier: 'Supplier',
        managerNotes: 'Manager Notes',
        serialNumber: 'Serial Number',
        deviceCode: 'Device Code',
        description: 'Description'
      },
      upload: {
        textContent: 'Click or drag file to this area to upload',
        hintUpload: 'Support for a single or bulk upload.',
        typeUpload: 'Allow type .jpg, .jpeg, .png, .gif.'
      },
      modalRequest: {
        titleModal: 'Create request',
        titleLabel: 'Title',
        device: 'Device',
        tracker: 'Tracker',
        priority: 'Priority',
        startDate: 'Start Date',
        dueDate: 'Due Date',
        decision: 'Decision'
      },
      modalDeleteDevice: {
        title: 'Delete Device',
        content: 'Are you sure to delete this device?'
      }
    },
    search: {
      byUserName: 'Search by User name',
      byTitleToName: 'Search by Title / Assignee name / Description / Author name / Device name',
      byDeviceNameToDeviceCodeFirst: 'Search by Device Name / Assignee name / Assignee email / Serial Number / Supplier / Description / Device Code',
      byDeviceNameToDeviceCodeSecond: 'Search by Device Name / Assignee name / Assignee email / Serial Number / Invoice Number / Supplier / Description / Device Code',
      byUpdater: 'Search by Updater / Assignee / Note',
      byTenant: 'Search by Tenant Name / Description / Subdomain',
      byGroups: 'Search by Groups Name / Description',
      byTracker: 'Search by tracker',
      byPriority: 'Search by priority',
      byStatus: 'Search by status',
      byStartDateForm: 'Start Date From',
      byStartDateTo: 'Start Date To',
      byDueDateForm: 'Due Date From',
      byDueDateTo: 'Due Date To',
      byRole: 'Search by role',
      byAllowedDomains: 'Search by allowed domains'
    },
    management: {
      title: 'Management',
      device: 'Devices',
      requests: {
        description: 'Description:',
        back: 'Back',
        closeRequest: 'Close request',
        updateRelated: 'Update related device',
        deleteRequest: 'Delete Request',
        updateRequest: 'Update',
        createRequest: 'Create',
        request: 'request',
        title: 'Requests',
        tableRequests: {
          title: 'Title',
          author: 'Author',
          assignee: 'Assignee',
          tracker: 'Tracker',
          deviceName: 'Device Name',
          priority: 'Priority',
          status: 'Status',
          startDate: 'Start Date',
          dueDate: 'Due Date',
          actions: 'Action'
        }
      },
      deviceHistory: {
        title: 'Device history',
        table: {
          Updater: 'Updater',
          updated: 'Updated',
          status: 'Status',
          assignee: 'Assignee',
          note: 'Note',
          actions: 'Actions'
        }
      }
    }
  },
  userForm: {
    user: 'User',
    email: 'Email',
    role: 'Role',
    action: 'Action'
  },
  cancel: 'Cancel',
  delete: 'Delete',
  update: 'Update',
  create: 'Create',
  back: 'Back',
  viewProfile: 'View Profile',
  signOut: 'Sign Out',
  month: 'month(s)',
  updateRole: 'Update role',
  removeUser: 'Remove User',
  finish: 'Finish',
  noData: 'No data',
  datepickerPlaceholder: 'Select date',
  triggerDesc: 'Click to sort descending',
  triggerAsc: 'Click to sort ascending',
  cancelSort: 'Click to cancel sorting',

  message: {
    requiredRule: 'This field is required',
    history: {
      updateSuccessfully: 'Update history note successfully!',
      error: 'Error',
      handleError: 'Something went wrong',
      note: 'Note',
      create: 'Create',
      update: 'Update'
    },
    deleteModalDevice: {
      title: 'Delete Device',
      content: 'Are you sure to delete this device'
    },
    deviceForm: {
      requiredOriginalPrice: {
        maximum: 'Original price is too long (maximum is 10 characters)',
        activationPurchase: 'Activation Date must be greater than or equal to Purchase Date',
        borrowPurchase: 'Borrow Date must be greater than or equal to Purchase Date',
        returnPurchase: 'Return Date must be greater than or equal to Purchase Date',
        returnBorrow: 'Return Date must be greater than or equal to Borrow Date'
      },
      maxMinValue: {
        lessThan: 'must be less than',
        greaterThan: 'must be greater than'
      },
      requiredDeviceName: 'Name is too long (maximum is 250 characters)',
      requiredDeviceType: 'Device type is too long (maximum is 20 characters)'
    },
    login: {
      success: 'Login successfully',
      error: 'Email has no access permission!'
    },
    deleteForm: {
      success: 'Delete the device successfully!'
    },
    closeRequestForm: {
      success: 'Close request successfully!',
      checkReturnDate: 'Return date must be greater than or equal to borrow date'
    },
    contentDetails: {
      error: "You don't have permission to view this data"
    },
    deleteFormRequest: {
      success: 'Delete the request successfully!',
      confirmDelete: 'Are you sure to delete this request?'
    },
    requestForm: {
      createSuccess: 'Create request successfully!',
      updateSuccess: 'Update request successfully!',
      checkDueDate: 'Due date must be greater than or equal to start date',
      requestAndDevice: 'Close request and update device'
    },
    deleteFormTenants: {
      success: 'Delete the tenant successfully!',
      confirmDelete: 'Are you sure to delete this tenant?'
    },
    detailsTenants: {
      name: 'Name:',
      description: 'Description:',
      table: {
        name: 'Name',
        description: 'Description',
        actions: 'Actions'
      }
    },
    tenantForm: {
      titleCreate: 'Create Tenant',
      titleUpdate: 'Update Tenant',
      titleDelete: 'Delete Tenant',
      titleDetail: 'Detail tenant',
      createSuccess: 'Create tenant successfully!',
      updateSuccess: 'Update tenant successfully!',
      name: 'Name',
      selectUser: 'Select User',
      placeholder: 'Please select',
      description: 'Description'
    },
    deleteFormUser: {
      success: 'Remove the user successfully!',
      confirm: 'Are you sure to remove this user?'
    },
    editRoleUser: {
      success: 'Update user successfully!',
      roles: 'Roles'
    },
    apiService: 'Session Expired!'
  },

  devices: {
    createDevice: 'Create device',
    updateDevice: 'Update device',
    statusDevice: {
      available: 'Available',
      broken: 'Broken',
      using: 'Using',
      warranty: 'Warranty',
      preparing: 'Preparing',
      deleted: 'Deleted'
    },

    deviceTypes: {
      company: 'Company',
      personal: 'Personal'
    }
  },

  requests: {
    myRequest: 'My request',
    authorName: 'Author Name',
    tracker: 'Tracker',
    status: 'Status',
    dueDate: 'Due Date',
    managerNotes: 'Manager Notes',
    deviceName: 'Device Name',
    assigneeName: 'Assignee Name',
    priority: 'Priority',
    startDate: 'Start Date',
    decision: 'Decision',
    description: 'Description'

  },
  common: {
    open: 'Open',
    inProgress: 'In Progress',
    approvedByManager: 'Approved by manager',
    approvedByAdmin: 'Approved by Admin',
    preparing: 'Preparing',
    rejected: 'Rejected',
    closed: 'Closed',
    all: 'All'
  },

  tracker: {
    borrow: 'Borrow',
    buy: 'Buy',
    return: 'Return',
    warranty_claim: 'Warranty claim'
  },

  priority: {
    low: 'Low',
    normal: 'Normal',
    high: 'High',
    urgent: 'Urgent',
    immediate: 'Immediate'
  },

  role: {
    user: 'User',
    manager: 'Manager',
    operator: 'Operator',
    admin: 'Admin'
  },

  login: {
    loginTitle: 'Login with Vareal ID',
    welcome: 'Welcome to Devices System',
    titleSignIn: 'Please sign in and start the adventure'
  },
  label: {
    name: 'Name',
    description: 'Description',
    actions: 'Action'
  },
  groups: {
    create: 'Create Group',
    update: 'Update Group',
    title: 'Groups',
    createSuccess: 'Create group successfully!',
    updateSuccess: 'Update group successfully!',
    selectManagerGroup: 'Select Manager Group',
    selectUserGroup: 'Select User Group',
    deleteSuccess: 'Delete the group successfully!',
    confirmDelete: 'Are you sure to delete this group?',
    titleDelete: 'Delete Group'
  }
}
